import _ from "lodash";
import { Reducer } from "..";
import { initData } from "./init";
import {
  Business,
  BusinessCategory,
  BusinessForm,
  BusinessItem,
} from "./state";

export * from "./state";

export type BusinessAction = {
  SetBusinessCategoryList: {
    type: "BUSINESS.SetBusinessCategoryList";
    payload: BusinessCategory[];
  };
  SetBusinessList: {
    type: "BUSINESS.SetBusinessList";
    payload: BusinessItem[];
  };
  SetContentMail: {
    type: "BUSINESS.SetContentMail";
    payload: BusinessForm;
  };
};

const reducer: Reducer<Business, ValueOf<BusinessAction>> = function (
  business = {
    businessList: _.sortBy(initData, "update_at"),
    APIGetBusinessListStatus: "COMPLETE",
    businessCategoryList: [
      {
        _id: "5f2288a5ee468100445b7eca",
        category: "ICT",
        imgUrl:
          "https://officialwebstorage.blob.core.windows.net/images/3607118281188242-ict.svg",
        index: "1",
      },
      {
        _id: "5f22924eee468100445b7ecd",
        category: "飲食",
        imgUrl:
          "https://officialwebstorage.blob.core.windows.net/images/21078659033912994-food.svg",
        index: "2",
      },
    ],
  },
  action
) {
  switch (action.type) {
    case "BUSINESS.SetBusinessCategoryList": {
      const { payload: businessCategoryList } = action;
      return {
        ...business,
        businessCategoryList,
      };
    }
    case "BUSINESS.SetBusinessList": {
      const { payload: businessList } = action;
      return {
        ...business,
        businessList,
        APIGetBusinessListStatus: "COMPLETE",
      };
    }
    case "BUSINESS.SetContentMail": {
      const { payload: businessFormData } = action;
      return {
        ...business,
        businessFormData,
        APISendMail: "NEED_TO_REFRESH",
      };
    }

    default: {
      return business;
    }
  }
};
export default reducer;
