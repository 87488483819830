import { createStyles, makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { seasonThemeColor } from "../../App";
import { useRouter } from "../../hooks";
import SVG from "../../SVG";
import LeftMenu from "../leftMenu";
import RightContents from "../rightContents/rightContents";
import RemoveMessage from "./../removeMessage";
import BackToTop from "./backToTop";
import Footer from "./footer";
import PanelLR from "./panelLR";
import Sakura from "./sakura";
import Snow from "./snow";
import Toas from "./toas";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}
export default function index(props: Props) {
  return (
    <>
      <Body {...props} />
      <BackToTop {...props} />
    </>
  );
}
export function Body(props: Props) {
  const season = useSelector((state) => state.ui.season);
  const matchesWidth = useMediaQuery("(max-width:700px)");
  const ipadWidth = useMediaQuery("(max-width:1024px)");
  const desktopHeight = useMediaQuery("(max-height:900px)");
  const matchesHeight = useMediaQuery("(min-height:1024px)");
  const heightIphone678 = useMediaQuery("(max-height:736px)");
  const {
    location: { pathname },
  } = useRouter();
  const mainBackground = season !== "winter" ? "#fff" : "#083090";
  const mainColor = season !== "winter" ? "#000" : "#fff";
  const treeUrl = `/images/tree/${season}_tree.png`;
  const BodyContainer = styled.div`
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
    width: 100vw;
    min-height: 100vh;
    position: relative;
  `;
  const FooterArea = styled.div`
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 101;
  `;
  const Main = styled.div`
    position: relative;
    min-height: ${matchesHeight ? 540 : 550}px;
    margin: auto;
    width: ${matchesWidth ? 100 : ipadWidth ? 80 : 70}%;
    background: url(${pathname.match(/(^\/home$)|(^\/$)/g) && treeUrl});
    background-repeat: no-repeat;
    background-size: auto 360px;
    background-position: center top;
    background-color: ${mainBackground};
    color: ${mainColor};
    z-index: 10;
    font-family: "EB Garamond", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "verdana", "Osaka",
      "ＭＳ Ｐゴシック", "MS PGothic", "Sans-Serif";
  `;
  const HeaderTop = styled.div`
    height: 100px;
    width: ${matchesWidth ? 100 : ipadWidth ? 80 : 70}%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `;

  return (
    <>
      <Toas />
      <RemoveMessage />
      {season === "winter" ? (
        <Snow />
      ) : (
        pathname.match(/(^\/home$)|(^\/$)/g) && <Sakura />
      )}
      <BodyContainer style={{ backgroundColor: `${seasonThemeColor[season]}` }}>
        <HeaderTop>
          <Link
            to="/"
            id="back-to-top-anchor"
            style={{
              height: matchesWidth?70:100,
              padding: "8px",
              display: "flex",
              // flexDirection: matchesWidth?"column-reverse":"row",
              alignItems:"center"
            }}
          >
            <img style={{
              width:matchesWidth?85:100,
              objectFit:"contain"
            }} src="/images/logo-top.svg" alt="logo" />
            
            <img style={{
              width:matchesWidth?190:450,
              objectFit:"contain"
            }} src="/images/15th.gif" alt="15th" />
            
          </Link>
          {/* <SelectBackground> */}
          <RightContents />
          {/* </SelectBackground> */}
          <MenuButton show={matchesWidth} />
        </HeaderTop>
        <Main>{props.children}</Main>
        <FooterArea>
          <Footer />
        </FooterArea>
        {heightIphone678 && pathname.match("/home") && (
          <div style={{ height: "100px" }}></div>
        )}
        {desktopHeight && !ipadWidth && <PanelLR />}
      </BodyContainer>
      {!matchesWidth && <LeftMenu />}
    </>
  );
}

type MenuButtonProps = {
  show: boolean;
  onShow?: () => void;
};
export function MenuButton({ onShow, show }: MenuButtonProps) {
  const menu = useSelector((s) => s.ui.menu);
  const [openPanel, setOpenPanel] = useState(false);
  const heightIphone678 = useMediaQuery("(max-height:736px)");
  let season = useSelector((s) => s.ui.season);
  const PanelContainer = styled.div`
    background-color: ${season !== "winter" ? "#fffe" : "#083090ee"};
    color: ${season === "winter" ? "#fff" : "#000"};
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    padding: "20px 0px 100px 0px";
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  `;
  const { history } = useRouter();
  const gotoPage = (link: string | undefined) => {
    if (!link) link = "#";
    history.push(link);
    setOpenPanel(false);
  };
  const MenuArea = styled.div`
    z-index: 9999;
    height: ${heightIphone678 ? 96 : 90}%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  `;
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      },
    })
  );
  const classes = useStyles();
  if (!show) return <div></div>;
  return !openPanel ? (
    <div
      onClick={() => {
        setOpenPanel(true);
      }}
    >
      <MenuIcon
        style={{ fontSize: "50px", paddingRight: "15px", color: "#000" }}
      />
    </div>
  ) : (
    <div className={classes.root}>
      <MenuIcon
        style={{
          fontSize: "50px",
          paddingRight: "15px",
          color: "rgba(0,0,0,0)",
        }}
      />
      <PanelContainer>
        <div
          style={{ position: "absolute", right: 10, top: 10 }}
          onClick={() => {
            setOpenPanel(false);
          }}
        >
          <CloseIcon
            style={{ fontSize: "50px", paddingRight: "15px", color: "inherit" }}
          />
        </div>
        <MenuArea>
          {menu.map((m, i) => {
            return (
              <div key={i}>
                <ContainerMenu
                  key={i}
                  onClick={() => {
                    if (m.linkTo && !m.linkTo.includes("http"))
                      gotoPage(m.linkTo);
                    else window.open(m.linkTo, "_self");
                  }}
                >
                  <MenuTitle>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SVG
                        style={{ height: 40 }}
                        svgName={`${season}_${m.title.toLowerCase()}`}
                      />
                      {m.title}
                      <div style={{ width: 20 }}> </div>
                    </div>
                  </MenuTitle>
                  <MenuTitleJp>{m.jpName || ""}</MenuTitleJp>
                  {m.buttonOther && (
                    <ButtonOther>
                      {m.buttonOther}
                      {m.buttonOther ? <>&rarr;</> : <></>}
                    </ButtonOther>
                  )}
                  <SubMenuContainer>
                    {m.subMenu &&
                      m.subMenu.map((sm, k) => {
                        return (
                          <SubMenuTitle key={k}>
                            {sm.title}
                            <ToRight>
                              <ChevronRightIcon
                                style={{
                                  height: "inherit",
                                  width: "inherit",
                                  position: "absolute",
                                  left: 0,
                                }}
                              />
                            </ToRight>
                          </SubMenuTitle>
                        );
                      })}
                  </SubMenuContainer>
                </ContainerMenu>
              </div>
            );
          })}
        </MenuArea>
      </PanelContainer>
    </div>
  );
}

const ContainerMenu = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: 70px;
  transition: all 500ms;
  padding-top: 15px;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
const MenuTitle = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  position: relative;
  width: fit-content;
`;
const MenuTitleJp = styled.h5`
  margin: 0;
  font-size: 0.7em;
`;
const SubMenuContainer = styled.div``;
const SubMenuTitle = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  width: 50vw;
  margin: 5px auto;
  justify-content: flex-end;
  text-align: center;
  transition: all 500ms;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
const ButtonOther = styled.div`
  background: #bfbfbf;
  padding: 3px 15px;
  font-size: 14px;
  margin-top: 5px;
  color: white;
  border-radius: 40px;
  transition: all 500ms;
  &:hover {
    background: gray;
    cursor: pointer;
  }
`;

const ToRight = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
  position: relative;
`;
