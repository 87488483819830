import { Status } from "../index";

import { Reducer } from "..";
import { MailState, Mail } from "./state";

export * from "./state";

export type MailAction = {
  SetConent: {
    type: "Mail.setContent";
    payload: Mail;
  };
  SetStatus: {
    type: "Mail.setstatus";
    payload: Status;
  };
};

const reducer: Reducer<MailState, ValueOf<MailAction>> = function (
  mail = {
    sendmail: {},
    sendMailStatus: "INITIAL",
  },
  action
) {
  switch (action.type) {
    case "Mail.setContent": {
      const { payload: sendmail } = action;
      return {
        ...mail,
        sendmail,
      };
    }
    case "Mail.setstatus": {
      const { payload: sendMailStatus } = action;
      return {
        ...mail,
        sendMailStatus,
      };
    }

    default: {
      return mail;
    }
  }
};
export default reducer;
