/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components/macro'

interface Props {
    style?: React.CSSProperties
    svgName?:string
}
export default function SVG({
    style,
    svgName
}: Props) {
    return (
        <Container style={style}>
            <img src={`./images/svg/${svgName||"autumn_home"}.svg`} alt={svgName} style={{transform:"scale(10)",marginTop:"5px",}} />
        </Container>
    )
}


const Container = styled.div`
    width: 40px;
    height:40px;
    overflow:hidden;
`

