import ui, { UI, UIAction } from "./ui";
import news, { NEWS, NewsAction } from "./news";
import business, { Business, BusinessAction } from "./business";
import mail, { MailState, MailAction } from "./contact";
import companyInfo, { CompanyInfo, CompanyInfoAction } from "./fourSeasonsInfo";
import { AnyAction } from "redux";

export type RootState = {
  ui: UI;
  news: NEWS;
  business: Business;
  mail: MailState;
  companyInfo: CompanyInfo;
};
export type Status =
  | "INITIAL"
  | "NEED_TO_REFRESH"
  | "LOADING"
  | "COMPLETE"
  | "ERROR";

export type AllActions =
  | ValueOf<UIAction>
  | ValueOf<NewsAction>
  | ValueOf<BusinessAction>
  | ValueOf<MailAction>
  | ValueOf<CompanyInfoAction>;

export type Reducer<TStateSlice, TAction> = (
  slicedState: DeepReadonly<TStateSlice> | undefined,
  action: TAction,
  rootState?: DeepReadonly<RootState> | undefined
) => DeepReadonly<TStateSlice>;

/**
 * 最も根幹となる reducer.
 *
 * @param state Root state
 * @param action 全アクションが渡ってくる
 */
export const rootReducer: Reducer<RootState, AnyAction> = function (
  state,
  action
) {
  return {
    ui: ui(state && state.ui, action as ValueOf<UIAction>, state),
    news: news(state && state.news, action as ValueOf<NewsAction>, state),
    business: business(
      state && state.business,
      action as ValueOf<BusinessAction>,
      state
    ),
    mail: mail(state && state.mail, action as ValueOf<MailAction>, state),
    companyInfo: companyInfo(
      state && state.companyInfo,
      action as ValueOf<CompanyInfoAction>,
      state
    ),
  };
};
