/* eslint-disable @typescript-eslint/no-unused-vars */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import React, { lazy } from "react";
import { GlobalStyle } from "./theme";
import App from "./App";
import NotFound from "./not-found";
import "./index.css";

import createStoreProvider from "./createStoreProvider";
import { rootReducer } from "./modules";

const { Provider } = createStoreProvider(rootReducer);

const pages: React.ComponentProps<typeof App>["pages"] = [
  { path: "/", component: lazy(() => import("./pages/home")) },
  { path: "/home", component: lazy(() => import("./pages/home")) },
  { path: "/news", component: lazy(() => import("./pages/news")) },
  { path: "/about", component: lazy(() => import("./pages/about")) },
  { path: "/contact", component: lazy(() => import("./pages/contact")) },
  { path: "/business", component: lazy(() => import("./pages/business")) },
  { path: "/recruit", component: lazy(() => import("./pages/recruit")) },
  { component: NotFound },
];

ReactDOM.render(
  <Provider>
    <GlobalStyle />
    <Router>
      <App pages={pages} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
