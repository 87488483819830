import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

interface Props {
  children?: any;
}
export default function HelmetComponent(props: Props) {
  const { lang, dir, title } = useSelector((state) => ({
    lang: state.ui.lang,
    dir: state.ui.dir,
    title: state.ui.title,
  }));

  return (
    <Helmet
      htmlAttributes={{ lang, dir }}
    >
      <title>
        {title
          ? `${title} | フォーシーズンズ株式会社`
          : "フォーシーズンズ株式会社｜Change the lifestyle"}
      </title>
    </Helmet>
  );
}
