import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  ogUrl?: string;
}
export default function Header({
  title = "ギャンブル依存症、性依存症、クレプトマニア、パラフィリア、病的窃盗",
  ogUrl = "https://www.0004s.com/app-def/S-102/blog",
}: Props) {
  return (
    <>
      <Helmet>
        <title>{title} | プラセボのレシピ</title>
        <meta name="robots" content="max-image-preview:large" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="・著者：山下悠毅（精神科専門医　精神保健指定医） ・所属：ライフサポートクリニック（東京都 豊島区） ・専門：ギャンブル依存症、性依存症、クレプトマニア、薬物依存症、認知行動療法"
        />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={ogUrl} />
        <meta
          property="og:image"
          content="https://www.0004s.com/app-def/S-102/blog/placebo_OGP-2.png"
        />
        <meta property="og:site_name" content="プラセボのレシピ" />
        <meta property="og:locale" content="ja_JP" />
        <meta
          property="article:published_time"
          content="2011-09-09T18:23:51+09:00"
        />
        <meta
          property="article:modified_time"
          content="2023-12-23T09:33:49+09:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:description"
          content="・著者：山下悠毅（精神科専門医　精神保健指定医） ・所属：ライフサポートクリニック（東京都 豊島区） ・専門：ギャンブル依存症、性依存症、クレプトマニア、薬物依存症、認知行動療法"
        />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:url" content={ogUrl} />
        <meta
          name="twitter:image"
          content="https://www.0004s.com/app-def/S-102/blog/placebo_OGP-2.png"
        />
        <meta name="twitter:domain" content="www.0004s.com" />
        <meta
          name="description"
          content="・著者：山下悠毅（精神科専門医　精神保健指定医） ・所属：ライフサポートクリニック（東京都 豊島区） ・専門：ギャンブル依存症、性依存症、クレプトマニア、薬物依存症、認知行動療法"
        />
        <meta
          name="thumbnail"
          content="https://www.0004s.com/app-def/S-102/blog/placebo_OGP-2.png"
        />
        <link rel="stylesheet" href="/placebo/style.css" />
      </Helmet>
      <header className="flex justify-center container mx-auto">
        <Link
          to="/app-def/S-102/blog/"
          className="aspect-[525/70] max-h-[100px] my-2"
        >
          <img src="/app-def/S-102/blog/placebo_logo.png" alt="logo" />
        </Link>
      </header>
      <nav className="h-10 md:h-12 flex justify-center items-center bg-[#305598]">
        <Link
          to="/app-def/S-102/blog/"
          className="flex w-1/3 md:w-[300px] text-white font-bold h-full justify-center items-center hover:bg-white/10 hover:no-underline"
        >
          ホーム
        </Link>
        <Link
          to="/app-def/S-102/blog/profile"
          className="flex w-1/3 md:w-[300px] text-white font-bold h-full justify-center items-center hover:bg-white/10 hover:no-underline"
        >
          Profile
        </Link>
      </nav>
    </>
  );
}
