import { Color } from "@material-ui/lab/Alert";
import { menu } from "./menu";
import { Reducer } from "..";
import { UI } from "./state";
import { getseason, random } from "../../util";

export * from "./state";

export type UIAction = {
  SetTitle: {
    type: "UI.SetTitle";
    payload: string;
  };
  SetSeason: {
    type: "UI.SetSeason";
    payload: string;
  };
  AddMessage: {
    type: "UI.AddMessage";
    payload: {
      type: Color;
      message: string;
    };
  };

  RemoveMessage: {
    type: "UI.RemoveMessage";
    payload: string;
  };
};

const reducer: Reducer<UI, ValueOf<UIAction>> = function (
  ui = {
    title: "",
    season: getseason(),
    menu: menu,
    messages: [
      // {
      //   key:"1",
      //   message:"Success",
      //   type:"success"
      // }
    ],
    lang: "ja",
    dir: "auto",
    // googleMapAPIKey:"AIzaSyAzLs7JrYjzN3eJzzjzmQlwDgcy_ROAyjU"
    // "AIzaSyBRs7NRGpboLvwqN9zqFZiuhCXqe9URYBQ"
  },
  action
) {
  switch (action.type) {
    case "UI.SetTitle": {
      const { payload: title } = action;
      return {
        ...ui,
        title,
      };
    }
    case "UI.SetSeason": {
      const { payload: season } = action;
      return {
        ...ui,
        season,
      };
    }
    case "UI.AddMessage": {
      const { payload: message } = action;
      return {
        ...ui,
        messages: [
          ...ui.messages,
          {
            key: random(),
            ...message,
          },
        ],
      };
    }

    case "UI.RemoveMessage": {
      const { payload: key } = action;
      return {
        ...ui,
        messages: ui.messages.filter((m) => m.key !== key),
      };
    }

    default: {
      return ui;
    }
  }
};
export default reducer;
