/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box50, Lable12, Box20 } from "../../theme";

export default function footer() {
  const season = useSelector((state) => state.ui.season);
  const matchesWidth = useMediaQuery("(max-width:1400px)");
  const urlFooterImage = `/images/fixed/background_footer_${season}.png`;
  const Container = styled.div`
    text-align: center;
    font-size: 12px;
    height: ${matchesWidth ? 155 : 280}px;
    width: 100vw;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${urlFooterImage});
    position: absolute;
    top: -150px;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding-bottom: 0px;
    pointer-events: none;
  `;
  return (
    <>
      <Container>
        <Box50 />
        <Box50 />
        <Box50 />
        <Lable12>
          Copyright © 2009-2020 Four Seasons inc. All Rights Reserved.
        </Lable12>
        <Box20 />
      </Container>
    </>
  );
}
