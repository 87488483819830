import { initData } from "./init";
import { Reducer } from "..";
import { CompanyInfo } from "./state";

export * from "./state";

export type CompanyInfoAction = {
  SetTitle: {
    type: "CompanyInfo.SetTitle";
    payload: string;
  };
};

const reducer: Reducer<CompanyInfo, ValueOf<CompanyInfoAction>> = function (
  companyInfo = initData,
  action
) {
  switch (action.type) {
    case "CompanyInfo.SetTitle": {
      const { payload: title } = action;
      return {
        ...companyInfo,
        title,
      };
    }

    default: {
      return companyInfo;
    }
  }
};
export default reducer;
