import { Menu } from ".";

export const menu: Menu[] = [
  {
    title: "Home",
    jpName: "ホーム",
    linkTo: "/",
    subMenu: [],
  },
  {
    title: "News",
    jpName: "お知らせ",
    linkTo: "/news",
    subMenu: [],
  },
  {
    title: "Company",
    jpName: "フォーシーズンズについて",
    linkTo: "/about",
    subMenu: [
      {
        title: "会社概要",
        linkTo: "#",
      },
      {
        title: "代表挨拶 ",
        linkTo: "#",
      },
    ],
  },
  {
    title: "Business",
    jpName: "事業内容",
    linkTo: "/business",
    subMenu: [],
  },
  {
    title: "Recruit",
    jpName: "採用情報",
    linkTo: "https://recruit.0004s.com/",
    subMenu: [],
  },
  {
    title: "Contact",
    jpName: "お問い合わせ",
    linkTo: "/contact",
    subMenu: [],
  },
];
