import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default function PanelLR() {
  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "space-between",
        zIndex: 0,
        pointerEvents: "none",
        overflow: "hidden",
        opacity: "0.4",
      },
      left: {
        backgroundImage: `url("/images/left.png")`,
        width: "25vw",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        opacity: 0.5,
      },
      right: {
        backgroundImage: `url("/images/right.png")`,
        width: "25vw",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        opacity: 0.6,
      },
    })
  )();
  return (
    <div className={useStyles.container}>
      <div className={useStyles.left}></div>
      <div className={useStyles.right}></div>
    </div>
  );
}
