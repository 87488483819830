import React from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components/macro";

export default function Sakura() {
  const season = useSelector((s) => s.ui.season);
  const Divsakura = styled.div`
    position:absolute;
    -webkit-animation: rotate ease infinite;
    -moz-animation: rotate ease infinite;
    -ms-animation: rotate ease infinite;
    -o-animation: rotate ease infinite;
    animation: rotate linear infinite;
    background-image:url("./images/${season}_furu.png");
    background-size:contain;
    background-position:center;
    background-repeat:no-repeat;
    overflow: hidden;
    `;

  const furu = (i: number, k?: number) => {
    let elmList: JSX.Element[] = [];
    for (let index = 0; index < i; index++) {
      elmList.push(
        <Divsakura
          key={index}
          style={{
            animationDuration: `${
              k ? Math.random() * 5 + 2 - k : Math.random() * 5 + 2
            }s`,
            right: `${Math.random() * 50}vw`,
            top: `${Math.random() * -100}%`,
            width: `${Math.random() * 16 + 4}px`,
            height: 30,
          }}
        ></Divsakura>
      );
    }
    return elmList;
  };
  const fallToLeft = (x: number) => {
    return keyframes`
    0% {
        transform: translate(${Math.random() * x}%, 0%);
        -webkit-transform: translate(20%, 0%);
    }
    100% {
        transform: translate(${Math.random() * -1 * x - 20}%, 200%);
        -webkit-transform: translate(-35%, 200%);
    }
    `;
  };
  const Container = styled.div`
    position: absolute;
    right: 0px;
    top: 0vh;
    animation: ${fallToLeft(40)} ease-in infinite 20s;
    width: 80vw;
    height: 80%;
    pointer-events: none;
  `;
  const Container2 = styled.div`
    position: absolute;
    right: 0px;
    top: -10vh;
    animation: ${fallToLeft(45)} ease-out infinite 20s;
    animation-delay: 7s;
    width: 80vw;
    height: 80%;
    pointer-events: none;
  `;
  const Container3 = styled.div`
    position: absolute;
    right: 0px;
    top: -10vh;
    animation: ${fallToLeft(40)} linear infinite 12s;
    animation-delay: 13s;
    width: 100%;
    height: 80%;
    pointer-events: none;
  `;
  const Box = styled.div`
    position: fixed;
    right: 15vw;
    top: 100px;
    width: 80vw;
    height: 80%;
    overflow: hidden;
    pointer-events: none;
    z-index: 9999;
  `;
  return (
    <Box>
      <Container>{furu(10)}</Container>
      <Container2>{furu(5)}</Container2>
      <Container3>{furu(3, 1)}</Container3>
    </Box>
  );
}
