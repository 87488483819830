export function getseason(): string {
  let result = "winter";
  let year = new Date().getFullYear();
  let spring = new Date(`${year}/03/01`);
  let summer = new Date(`${year}/06/01`);
  let autumn = new Date(`${year}/09/01`);
  let winter = new Date(`${year}/12/01`);
  let today = new Date();
  if (today >= spring && today < summer) {
    return (result = "spring");
  } else if (today >= summer && today < autumn) {
    return (result = "summer");
  } else if (today >= autumn && today < winter) {
    return (result = "autumn");
  }
  return result;
}
