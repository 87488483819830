/* eslint-disable no-useless-escape */
import { CompanyInfo } from ".";

export const initData: CompanyInfo = {
  companyName: "フォーシーズンズ株式会社　Four Seasons inc.",
  president: "松浦 世裕",
  director: "山下 悠毅",
  established: "平成21年9月",
  capital: "1,000万円",
  staff: "31名",
  mail: "info@0004s.com",
  businessContent: `インフラ構築、アプリケーション開発、ウェブ制作・開発、グラフィックデザイン、飲食店経営`,
  branch: [
    {
      name: "本社",
      postcode: "〒353-0007",
      address: "埼玉県志木市柏町4-3-40",
      linkMap: "https://goo.gl/maps/yvhVayF2af4bt4m16",
      linkFrame:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1617.414921924745!2d139.5721278!3d35.8286503!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c27f44f103d9%3A0x2eacf9d0bffcfe10!2z5pel5pys44CB44CSMzUzLTAwMDcg5Z-8546J55yM5b-X5pyo5biC5p-P55S677yU5LiB55uu77yT4oiS77yU77yQ!5e0!3m2!1sja!2sus!4v1588923497903!5m2!1sja!2sus",
      place: {
        lat: 35.8288402,
        lng: 139.5707017,
      },
      tel: "048-487-5587",
      fax: "048-487-5587",
    },
    {
      name: "東京支社／DevLab",
      postcode: "〒103-0013",
      address: "東京都中央区日本橋人形町1-1-10　麻業会館5階",
      linkMap: "https://goo.gl/maps/vLuD1H9zZkPwFJhD8",
      linkFrame:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.6765632523125!2d139.780235!3d35.6849652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018895052413c31%3A0x41b3548b49acb427!2z44OV44Kp44O844K344O844K644Oz44K677yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1587364546096!5m2!1sja!2sjp",
      place: {
        lat: 35.685342,
        lng: 139.7816368,
      },
      tel: "03-5614-0746",
      fax: "03-5614-0747",
    },
    {
      name:
        "東京支社：R&E部（<a href='https://nihombashimatsuura.com/' target='_blank'>仕出し松うら</a>）",
      postcode: "〒103-0007 ",
      address: "東京都中央区日本橋浜町2-35-4 浜町パークビル1階",
      linkMap: "https://goo.gl/maps/zMwK5J9wW8bKSji59",
      linkFrame:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.5236702898865!2d139.78406531525897!3d35.68872898019263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018894e91dc3fcb%3A0x1fc3dc760e03c3a9!2sBOOKSHELF%20CAFE!5e0!3m2!1sja!2sjp!4v1587364680170!5m2!1sja!2sjp",
      website: "https://nihombashimatsuura.com/",
      place: {
        lat: 35.6887555,
        lng: 139.7841479,
      },
      tel: "03-5614-0241",
      fax: "03-5614-0242",
    },
  ],
};
