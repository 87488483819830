import React, { useEffect } from 'react'

export default function RedirectBlog() {
  useEffect(() => {
    window.location.assign("https://placebo.0004s.com")
  }, [])
  
  return (
    <div>Loading...</div>
  )
}
