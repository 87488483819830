import _ from "lodash";
import { Reducer } from "..";
import { initData } from "./init";
import { NEWS, News } from "./state";

export * from "./state";

export type NewsAction = {
  SetTitle: {
    type: "NEWS.SetTitle";
    payload: string;
  };
  SetNews: {
    type: "NEWS.SetNews";
    payload: {
      newslist: News[];
    };
  };
  SetNewsIDSelected: {
    type: "NEWS.SetNewsIDSelected";
    payload: string;
  };
  SetShowModalView: {
    type: "NEWS.SetShowModalView";
    payload: boolean;
  };
};

const reducer: Reducer<NEWS, ValueOf<NewsAction>> = function (
  news = {
    newslist: _.reverse(_.sortBy(initData, "display_date")),
    APIGetNewsStatus: "COMPLETE",
    showModalViewDetails: false,
  },
  action
) {
  switch (action.type) {
    case "NEWS.SetTitle": {
      const { payload: title } = action;
      return {
        ...news,
        title,
      };
    }
    case "NEWS.SetNews": {
      const { newslist } = action.payload;
      return {
        ...news,
        newslist,
        APIGetNewsStatus: "COMPLETE",
      };
    }
    case "NEWS.SetNewsIDSelected": {
      const { payload: newsIDSelected } = action;
      return {
        ...news,
        newsIDSelected,
      };
    }
    case "NEWS.SetShowModalView": {
      const { payload: showModalViewDetails } = action;
      return {
        ...news,
        showModalViewDetails,
      };
    }

    default: {
      return news;
    }
  }
};
export default reducer;
