import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useRouter } from "./hooks";

export default function NotFound() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "UI.SetTitle",
      payload: "404: Not found",
    });
  }, [dispatch]);
  const { history } = useRouter();
  return (
    <Container>
      <div
        style={{
          display: "block",
          padding: "50px auto",
          background: "rgba(255,255,255,0.4)",
        }}
      >
        <h2 style={{ display: "inlineBlock", paddingTop: "25px" }}>
          お探しのページが見つかりませんでした。
        </h2>
        <br />
        <p style={{ display: "inlineBlock", padding: "0 50px 50px" }}>
          お探しのページは「すでに削除されている」、「アクセスしたアドレスが異なっている」などの理由で見つかりませんでした。
        </p>
        <div>
        <p
          onClick={() => {
            history.push("/");
          }}
        >
          <Text>トップページへ戻る</Text>
        </p>
      </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  stroke: red;
  padding: 10vh 0px;
`;
const Text = styled.span`
  padding: 5px 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid;
  width: 100px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    color: red;
  }
`;