import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

interface Props {
  searchText?: string;
  setSearchText?: (val: string) => void;
  date?: { [key: string]: string[] };
}
export default function RightPanel({
  date,
  searchText = "",
  setSearchText,
}: Props) {
  const history = useHistory();
  const q = new URLSearchParams(history.location.search).get("q");
  return (
    <section className="bg-white rounded w-full md:w-[320px] p-4">
      {!q && setSearchText && (
        <div className="mb-4 flex flex-col gap-2">
          <h2 className="font-semibold text-xl bg-[#f5f6f7] p-2">検索</h2>
          <div className="flex gap-1 items-stretch w-full">
            <input
              type="search"
              className="border rounded p-1 text-base flex-1 outline-none"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className="w-20 bg-blue-800 flex justify-center items-center text-white rounded"
              onClick={() => {
                history.push(
                  `/app-def/S-102/blog/${
                    searchText ? "?q=" + encodeURIComponent(searchText) : ""
                  }`
                );
              }}
            >
              検索
            </button>
          </div>
        </div>
      )}
      <hr className="my-2" />
      <h2 className="font-semibold text-xl bg-[#f5f6f7] p-2">運営者について</h2>
      <ul className="list-inside list-disc ml-2 leading-8">
        <li>
          <a
            href="https://www.0004s.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            4s
          </a>
        </li>
        <li>
          <a
            href="https://recruit.0004s.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            4s recruit
          </a>
        </li>
        <li>
          <a
            href="https://note.com/0004s_marketing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            4s NOTE
          </a>
        </li>
        <li>
          <a
            href="https://nihombashimatsuura.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            仕出し松うら
          </a>
        </li>
        <li>
          <a
            href="https://marutake.0004s.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            まる竹
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/bookshelfcafe"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline hover:text-blue-400"
          >
            BOOKSHELFCAFE X
          </a>
        </li>
      </ul>
      {date && (
        <>
          <hr className="my-2" />
          <h2 className="font-semibold text-xl bg-[#f5f6f7] p-2">アーカイブ</h2>
          <div>
            {Object.keys(date)
              .sort((a, b) => parseInt(b) - parseInt(a))
              .map((y) => {
                return (
                  <details key={y}>
                    <summary>{y}</summary>
                    <div className="flex gap-1 flex-wrap">
                      {date[y].map((m) => {
                        return (
                          <Link
                            to={`/app-def/S-102/blog?y=${parseInt(
                              y
                            )}&m=${parseInt(m).toString().padStart(2, "0")}`}
                            key={m}
                          >
                            {m}
                          </Link>
                        );
                      })}
                    </div>
                  </details>
                );
              })}
          </div>
        </>
      )}
    </section>
  );
}
