import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { IComment } from ".";
import axiosClient from "../../util/axiosClient";

interface IFormInput {
  name: string;
  email: string;
  content: string;
}

interface Props {
  pId: string;
  comments: IComment[];
}
export default function Comment({ pId, comments }: Props) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<IFormInput>({
    name: "",
    email: "",
    content: "",
  });
  const [check, setCheck] = useState(!!localStorage.getItem("info"));
  const [initialed, setInitialed] = useState(false);
  useEffect(() => {
    const s = localStorage.getItem("info");
    if (s) {
      const info = JSON.parse(s);
      setState({
        ...info,
      });
    }
  }, []);

  useEffect(() => {
    const fn = () => {
      const { SUNEDITOR, SUNEDITOR_LANG } = window as any;
      if (typeof SUNEDITOR === "object") {
        const div = document.createElement("div");
        const honbun = document.getElementById("honbun")!;
        honbun.innerHTML = "";
        honbun.appendChild(div);
        const editor = SUNEDITOR.create(div, {
          lang: SUNEDITOR_LANG["ja"],
          stickyToolbar: "0",
          defaultStyle: "font-size:16px;padding:0;",
          tagsBlacklist: "script|link|meta",
          pasteTagsBlacklist: "script|link|meta",
          defaultTag: "p",
          display: "block",
          popupDisplay: "full",
          minHeight: "200px",
          height: "100%",
          fontSize: [8, 10, 12, 14, 16, 18, 20, 24, 36, 48],
          fontSizeUnit: "px",
          placeholder: "入力。。。",
          charCounter: true,
          buttonList: [
            [
              "undo",
              "redo",
              "fontSize",
              "formatBlock",
              "paragraphStyle",
              "blockquote",
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
              "fontColor",
              "hiliteColor",
              "textStyle",
              "removeFormat",
              "outdent",
              "indent",
              "align",
              "list",
              "table",
              "link",
              "image",
              "fullScreen",
            ],
          ],
        });
        editor.onChange = (contents: string) => {
          setState((pre) => ({
            ...pre,
            content: contents,
          }));
        };
        setInitialed(true);
      }
    };
    const timer = setInterval(() => {
      if (initialed) {
        clearInterval(timer);
      } else {
        fn();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialed]);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    try {
      setLoading(true);
      if (check) {
        localStorage.setItem(
          "info",
          JSON.stringify({
            name: state.name,
            email: state.email,
          })
        );
      }
      await axiosClient.post("/api/comment", {
        ...state,
        postId: pId,
      });
      alert(
        "送信しました。まだ表示されていませんが、管理者がチェックしてから、表示します。"
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="">
      <h3 className="text-xl font-bold">コメント欄</h3>
      <div className="flex flex-col gap-4 mt-4">
        {comments.map((v) => {
          return (
            <div key={v._id} className="flex flex-col gap-2 p-2 border rounded">
              <div className="flex justify-between">
                <span className="text-sm font-semibold">{v.name}より：</span>
                <span className="text-xs">
                  {dayjs(v.date).format("YYYY年MM月DD日 HH:mm")}
                </span>
              </div>
              <hr />
              <div
                className="sun-editor-editable !p-0"
                dangerouslySetInnerHTML={{ __html: v.content }}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="w-full border shadow-md flex flex-col p-2 rounded-lg gap-1 items-stretch my-4">
        <h3 className="text-xl font-bold">コメント入力</h3>
        <div className="my-4 text-sm">
          <span>メールアドレスが公開されることはありません。</span>
          <span>
            <span className="text-red-600">※</span>
            が付いている欄は必須項目です
          </span>
        </div>
        <label className="w-max">
          名前<span className="text-red-600">※</span>
        </label>
        <input
          value={state.name}
          name="name"
          required
          onChange={handleChange}
          className={
            "w-full max-w-md border rounded flex-1 outline-none p-1 text-base"
          }
        />
        <label className="w-max">
          メールアドレス<span className="text-red-600">※</span>
        </label>
        <input
          value={state.email}
          name="email"
          required
          type="email"
          onChange={handleChange}
          className={
            "w-full max-w-md border rounded flex-1 outline-none p-1 text-base"
          }
        />
        <div className="flex flex-col">
          <label>
            コメント<span className="text-red-600">※</span>
          </label>
          <div id="honbun" className="cursor-text"></div>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            name="check"
            id="check"
            checked={check}
            onChange={(e) => setCheck(e.target.checked)}
          />
          <label className="flex-1" htmlFor="check">
            次回のコメントで使用するためブラウザーに自分の名前、メールアドレス、サイトを保存する。
          </label>
        </div>

        <div className="flex items-center my-4 gap-4">
          <button
            disabled={
              loading ||
              !Object.values(state).every((f) => f.trim().length !== 0)
            }
            className="border rounded py-1 w-60 flex justify-center hover:bg-gray-100 disabled:text-gray-200 disabled:pointer-events-none"
            onClick={onSubmit}
          >
            {loading ? (
              <div className="flex gap-1 items-center">
                <svg
                  className="w-6 h-6 text-gray-200 animate-spin fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span>送信中。。。</span>
              </div>
            ) : (
              <div className="flex gap-1 items-center">
                <span>コメントを送信</span>
                <IoIosSend />
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
