/* eslint-disable react-hooks/rules-of-hooks */
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import styled from "styled-components/macro";
import ErrorBoundary from "./ErrorBoundary";
import Helmet4S from "./Helmet";
import Layout from "./components/layouts";
// import Purasebo from "./components/Purasebo";
// import Profile from "./components/Purasebo/Profile";
import Detail from "./components/Purasebo/Detail";
import RedirectBlog from "./RedirectBlog";

type Props = {
  pages: {
    path?: string;
    component: Parameters<typeof React.createElement>[0];
  }[];
};
export const seasonThemeColor = {
  spring: "#ECF3CF",
  summer: "#A1D8E6",
  autumn: "#F1EBD4",
  winter: "#FFFFFF",
} as any;
export function getColorPrimary(ss?: string) {
  let season = ss?.toLowerCase() || useSelector((s) => s.ui.season);
  switch (season) {
    case "spring":
      return seasonThemeColor.spring;
    case "summer":
      return seasonThemeColor.summer;
    case "autumn":
      return seasonThemeColor.autumn;
    case "winter":
      return seasonThemeColor.winter;
    default:
      return "#fff";
  }
}
export function getTextColor(ss?: string) {
  let season = ss?.toLowerCase() || useSelector((s) => s.ui.season);
  if (season === "winter") {
    return "#ffffff";
  }
  return "#000000";
}
export function getSeason() {
  return useSelector((s) => s.ui.season);
}

export default function App({ pages = [] }: Props) {
  const season = useSelector((s) => s.ui.season);
  const theme = createMuiTheme({
    overrides: {
      MuiTypography: {
        root: {
          padding: 0,
        },
        colorTextSecondary: {
          color: "inherit",
        },
        colorTextPrimary: {
          color: "inherit",
        },
      },
      MuiStepper: {
        root: {
          padding: "10px 2px",
        },
      },
      MuiFormLabel: {
        root: {
          color: `${season === "winter" ? "#fff" : "#000"}`,
        },
        asterisk: {
          color: "red",
        },
      },
      MuiTextField: {
        root: {
          "&$focused": {},
        },
      },
      MuiInputLabel: {
        root: {
          "&$focused": {},
        },
      },
      MuiOutlinedInput: {
        root: {
          "&$focused": {
            // color: "red",
            outline: "none",
          },
        },
      },
      MuiButton: {
        root: {
          minWidth: 0,
          color: `${season === "winter" ? "#fff" : "#000"}`,
          "&:hover": {
            backgroundColor: getColorPrimary(),
          },
          "@media (hover:none)": {
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0)!important",
              color: `${season === "winter" ? "#fff" : "#000"}`,
            },
          },
        },
        text: {},
        contained: {
          color: `${season === "winter" ? "#fff" : "#000"}`,
          backgroundColor: "inherit",
        },
      },
      MuiTab: {
        textColorPrimary: {
          color: getColorPrimary(),
        },
      },
      MuiFab: {
        root: {
          backgroundColor: getColorPrimary(),
          "&:hover": {
            backgroundColor: getColorPrimary(),
          },
          "@media (hover:none)": {
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0)!important",
            },
          },
        },
        primary: {
          backgroundColor: getColorPrimary(),
          "&:hover": {
            backgroundColor: getColorPrimary(),
          },
        },
      },
      MuiPaper: {
        root: {
          color: "inherit",
        },
        elevation4: {
          boxShadow: "none",
        },
      },
      MuiTabs: {
        root: {},
        flexContainer: {},
        indicator: {},
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
      MuiSvgIcon: {
        root: {
          color: "inherit",
        },
      },
      MuiCheckbox: {
        root: {
          color: `${season === "winter" ? "#fff" : "#000"}`,
        },
      },
    },
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Switch>
            <Route path={"/app-def/S-102/blog/*"} component={RedirectBlog} />
            {/* <Route
              path={"/app-def/S-102/blog/profile"}
              exact
              component={Profile}
            /> */}
            <Route path={"/app-def/S-102/blog/:id"} component={Detail} />
            {pages.map(({ path, component }) => (
              <Route
                key={path || ""}
                path={path}
                exact
                render={() => (
                  <>
                    <Helmet4S></Helmet4S>
                    <Layout>
                      <Suspense fallback={<Loading />}>
                        {React.createElement(component)}
                      </Suspense>
                    </Layout>
                  </>
                )}
              />
            ))}
          </Switch>
        </ErrorBoundary>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
const Loading = styled.div``;
