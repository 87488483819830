import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as queryString from "querystring";
const axiosClient: AxiosInstance = Axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3002"
      : "https://placebo-admin.0004s.com",
  paramsSerializer: (param) => queryString.stringify(param),
});
axiosClient.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    return {
      ...config,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
