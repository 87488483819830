import Fab from "@material-ui/core/Fab";
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
// import { getColorPrimary } from '../../App';
// import { useSelector } from 'react-redux';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "fixed",
      bottom: "70px",
      right: "20px",
      zIndex: 9999,
    },
  })
);

function ScrollTop(props: Props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 10,
  });

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function BackToTop(props: Props) {
  // const winter = useSelector(s => s.ui.season) === "winter"
  const theme = createMuiTheme({
    overrides: {
      MuiFab: {
        root: {
          backgroundColor: "rgba(223, 195, 113, 0.6)",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d4ad3e",
          },
          "@media (hover:none)": {
            "&:hover": {
              backgroundColor: "#d4ad3e !important",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <ScrollTop {...props}>
        <Fab size="small">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  );
}

export const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  const anchor = (
    (event.target as HTMLDivElement).ownerDocument || document
  ).querySelector("#back-to-top-anchor");

  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
