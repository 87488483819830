/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from "@material-ui/icons/Email";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Home from "@material-ui/icons/Home";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import React from "react";
import { useSelector } from "react-redux";
import { useRouter } from "../../hooks";

export default function leftMenu() {
  const [open, setOpen] = React.useState(false);
  const winter = useSelector((s) => s.ui.season) === "winter";

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "200px",
        background: "rgba(225,225,225,0.95)",
        zIndex: 2000,
        animation: "fromleft",
        animationDuration: "400ms",
      },
      grayBG: {
        background: "rgba(0,0,0,0.3)",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 20,
      },
      buttonMenu: {
        position: "absolute",
        top: 30,
        left: 20,
        cursor: "pointer",
        borderRadius: "50%",
      },
      iconMenu: {
        color: "black",
        fontSize: 44,
      },
      list: {
        width: 200,
        color: `${!winter ? "black" : "black"}`,
      },
    })
  );
  const menu = useSelector((s) => s.ui.menu);
  const classes = useStyles();
  const getIcon = (text: string) => {
    switch (text) {
      case "home":
        return <Home />;
      case "news":
        return <InfoOutlinedIcon />;
      case "contact":
        return <EmailIcon />;
      case "recruit":
        return <GroupAddIcon />;
      case "business":
        return <BusinessCenterIcon />;
      default:
        return <BusinessIcon />;
    }
  };
  const { history } = useRouter();
  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={(e) => {
        setOpen(!open);
      }}
    >
      <List>
        {menu.map((m, index) => (
          <div
            key={index}
            onClick={() => {
              if (m.linkTo && !m.linkTo.includes("http"))
                history.push(m.linkTo);
              else window.open(m.linkTo, "_blank");
            }}
          >
            <ListItem button>
              <ListItemIcon>{getIcon(m.title.toLowerCase())}</ListItemIcon>
              <ListItemText primary={m.title} />
            </ListItem>
          </div>
        ))}
      </List>
    </div>
  );
  return !open ? (
    <Button
      className={classes.buttonMenu}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <MenuIcon className={classes.iconMenu} />
    </Button>
  ) : (
    <>
      <div className={classes.grayBG} onClick={() => setOpen(false)}></div>
      <div className={classes.root}>{list()}</div>
    </>
  );
}
