/* eslint-disable react-hooks/rules-of-hooks */
import styled, { createGlobalStyle } from "styled-components/macro";

const fontFamily: string = [
  "EB Garamond",
  "ヒラギノ角ゴ Pro W3",
  "Hiragino Kaku Gothic Pro",
  "メイリオ",
  "Meiryo",
  "verdana",
  "Osaka",
  "ＭＳ Ｐゴシック",
  "MS PGothic",
  "Sans-Serif",
].join(", ");

export const GlobalStyle = createGlobalStyle`
  :root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.03em;
    font-family: ${fontFamily};
    background-color: #EDEFF5;
    box-sizing:border-box;
  }
  ::selection {
    color: inherit;
    background:bisque;
  }
`;
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;
export const Title = styled.h1`
  text-transform: capitalize;
  margin: 20px auto;
  text-align: left;
`;
export const JpLable = styled.span`
  padding: 10px auto;
  font-size: 18px;
  font-weight: 500;
`;
export const Box10 = styled.div`
  display: block;
  width: 10px;
  height: 10px;
`;
export const Box20 = styled.div`
  display: block;
  width: 20px;
  height: 20px;
`;
export const Box50 = styled.div`
  display: block;
  width: 50px;
  height: 50px;
`;
export const Box100 = styled.div`
  display: block;
  width: 50px;
  height: 50px;
`;
export const Lable10 = styled.p`
  font-size: 10px;
`;
export const Lable12 = styled.p`
  font-size: 12px;
`;
export const Lable14 = styled.p`
  font-size: 14px;
`;
export const Lable16 = styled.p`
  font-size: 16px;
`;
export const Lable18 = styled.p`
  font-size: 18px;
`;
export const Lable20 = styled.p`
  font-size: 20px;
`;
