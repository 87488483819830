/* eslint-disable react-hooks/rules-of-hooks */
import { FabTypeMap, useMediaQuery } from "@material-ui/core";
import { OverrideProps } from "@material-ui/core/OverridableComponent";
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import CloseIcon from "@material-ui/icons/Close";
import CloudIcon from "@material-ui/icons/Cloud";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColorPrimary } from "../../App";

export default function rightContents() {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const seasonState = useSelector((state) => state.ui.season);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState(seasonState);
  const iphone = useMediaQuery("(max-width:700px)");
  const actions = [
    { icon: <FilterVintageIcon />, name: "spring", value: 0 },
    { icon: <CloudIcon />, name: "summer", value: 1 },
    { icon: <FavoriteIcon />, name: "autumn", value: 2 },
    { icon: <AcUnitIcon />, name: "winter", value: 3 },
  ];

  const useStyles = makeStyles(() =>
    createStyles({
      speedDial: {
        position: "absolute",
        top: 1,
        right: iphone ? 45 : 10,
        // top: `${iphone ? -50 : -45}px`,
        // right: `${iphone ? -70 :ipad? -200 :ipadProWidth&&ipadProHeight ? -300 : -300}px`,
      },
      sub: {
        borderRadius: "50%",
        backgroundImage: "none",
        backgroundColor: getColorPrimary(),
        color: "#fff",
        "&:hover": {
          background: getColorPrimary(),
          transform: "scale(1.4)",
        },
      },
    })
  );
  const matches = useMediaQuery("(max-width:700px)");
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "#fff",
          backgroundColor: "rgba(0,200,0,0.8)",
        },
      },
      MuiFab: {
        root: {
          boxShadow: `${
            matches ? "0px 0px 10px rgba(0,0,0,0.00)" : "0px 0px 10px -6px none"
          }`,
          backgroundImage: `url(./images/svg/shiki_${seasonState}.svg)`,
          backgroundSize: seasonState !== "summer" ? "800%" : "680%",
          backgroundColor: "rgba(0,0,0,0)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          margin: 0,
          borderRadius: "50%",
          width: `${matches ? "95px" : "95px"}`,
          height: `${matches ? "100px" : "90px"}`,
          fontSize: "10px",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0)",
          },
          "@media (hover:none)": {
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0)!important",
            },
          },
        },
        primary: {},
        label: {
          color: "black",
          fontFamily: "EB Garamond",
          fontSize: 14,
          position: "relative",
        },
      },
      MuiSvgIcon: {
        root: {
          borderRadius: "50%",
          color: "#fff",
        },
      },
    },
  });

  const handleOpen = () => {
    if (!matches) setOpen(true);
    else setShow(!show);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        icon={
          <SpeedDialIcon
            icon={
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  left: 0,
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {"Shiki"}
              </div>
            }
            openIcon={
              <div
                style={{
                  position: "absolute",
                  left: "0px",
                  width: "100%",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {"Shiki"}
              </div>
            }
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={matches ? "down" : "left"}
        FabProps={styleFab}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            placement="bottom-start"
            onClick={(e) => {
              setOpen(false);
              setName(action.name);
              dispatch({
                type: "UI.SetSeason",
                payload: action.name,
              });
            }}
            className={`${action.name} ${classes.sub}`}
            FabProps={{ size: "small" }}
          />
        ))}
      </SpeedDial>
      <FullScreen
        show={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </ThemeProvider>
  );
}
const styleFab: Partial<OverrideProps<FabTypeMap<{}, "button">, "button">> = {
  color: "inherit",
  size: "large",
};

type FProps = {
  show: boolean;
  onClose: () => void;
};

export function FullScreen({ show, onClose }: FProps) {
  const dispatch = useDispatch();
  const listSeasons = ["Spring", "Summer", "Autumn", "Winter"];
  const useStyles = makeStyles({
    fullList: {
      width: "100vw",
      height: "100vh",
      position: "fixed",
      left: "0",
      top: "0",
      zIndex: 1200,
      backgroundColor: "#d4ad3e", // `${getColorPrimary().concat("dd")}`,
    },
    itemMenu: {
      zIndex: 1300,
      textAlign: "center",
      fontSize: "32px",
      padding: 10,
      margin: 10,
      fontWeight: "bold",
      borderRadius: "40px",
      cursor: "pointer",
      // boxShadow: "0px 2px 10px gray",
    },
    iconCloseContainer: {
      textAlign: "right",
      padding: "25px 10px",
      width: "100vw",
      // backgroundColor: `${getColorPrimary().concat("99")}`,
    },
    iconClose: {
      fontSize: "48px",
      color: "#000",
    },
  });

  const classes = useStyles();
  const list = listSeasons.map((s, i) => {
    return (
      <div
        className={classes.itemMenu}
        key={i}
        onClick={() => {
          onClose();
          dispatch({
            type: "UI.SetSeason",
            payload: s.toLocaleLowerCase(),
          });
        }}
        style={
          {
            // backgroundColor:`${getColorPrimary(s)}`
          }
        }
      >
        {s}
      </div>
    );
  });
  if (!show) return <></>;
  return (
    <div
      className={classes.fullList}
      onClick={() => {
        onClose();
      }}
    >
      <div className={classes.iconCloseContainer}>
        <CloseIcon className={classes.iconClose} />
      </div>
      {list}
    </div>
  );
}
