import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-white pt-6">
      <div className="container mx-auto py-2 flex justify-between flex-col md:flex-row">
        <div className="flex-1 flex">
          <Link
            to="/app-def/S-102/blog/"
            className="aspect-[525/70] h-[60px] max-w-full"
          >
            <img src="/app-def/S-102/blog/placebo_logo.png" alt="logo" />
          </Link>
        </div>
        <div className="flex-2 flex flex-col gap-1 items-end">
          <div className="flex gap-3 items-center justify-end">
            <i className="h-full w-[1px] bg-[#aaa]"></i>
            <Link
              to="/app-def/S-102/blog/"
              className="hover:bg-gray-50 text-blue-900 underline"
            >
              ホーム
            </Link>
            <i className="h-full w-[1px] bg-[#aaa]"></i>
            <Link
              to="/app-def/S-102/blog/profile"
              className="hover:bg-gray-50 text-blue-900 underline"
            >
              Profile
            </Link>
            <i className="h-full w-[1px] bg-[#aaa]"></i>
          </div>
          <p className="text-xs md:text-sm">
            Copyright © {new Date().getFullYear()} プラセボのレシピ All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
