/* eslint-disable no-restricted-globals */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaFolder, FaLine, FaRegCopy, FaXTwitter } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";
import { useHistory, useParams } from "react-router";
import { IComment, IPosts } from ".";
import NotFound from "../../not-found";
import axiosClient from "../../util/axiosClient";
import Footer from "./Footer";
import Header from "./Header";
import RightPanel from "./RightPanel";
import Comment from "./Comment";
import Helmet from "react-helmet";

export default function Detail() {
  const { id } = useParams<{ id: string }>();
  const [posts, setPosts] = useState<IPosts>();
  const [comments, setComments] = useState<IComment[]>([]);
  const [relation, setRelation] = useState<IPosts[]>();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      (async () => {
        setLoading(true);
        try {
          const { data } = await axiosClient.get<{
            posts: IPosts;
            relation: IPosts[];
            comments: IComment[];
          }>("/api/posts/" + id);
          setPosts({
            ...data.posts,
          });
          setComments(data.comments);
          setRelation(data.relation.slice(0, 5));
        } catch (err) {
          setPosts(undefined);
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [id]);
  useEffect(() => {
    document.querySelectorAll<HTMLElement>("p:first-child").forEach((elm) => {
      if (elm.innerText.trim().length === 0) elm.remove();
    });
  });

  if (!posts && loading)
    return <p className="text-5xl text-center font-bold">読み込み中...</p>;
  else if (!posts) return <NotFound />;
  return (
    <div>
      <Helmet>
        <link
          href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css"
          rel="stylesheet"
        />
        <script src="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/suneditor.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/suneditor@latest/src/lang/ja.js"></script>
      </Helmet>
      <Header
        title={posts.title}
        ogUrl={"https://www.0004s.com/app-def/S-102/blog/" + posts._id}
      />

      <main className="bg-[#f4f5f7] pb-6">
        <div className="container mx-auto flex items-start py-4 gap-4 flex-wrap">
          <section className="bg-white rounded flex-1 p-4">
            <div className="my-2 flex flex-col md:flex-row justify-between gap-2">
              <h2 className="font-semibold text-xl">{posts.title}</h2>
              <div className="flex justify-end items-center gap-1">
                <IoTimeOutline />
                <span className="text-sm">
                  {dayjs(posts.date).format("YYYY.MM.DD")}
                </span>
              </div>
            </div>
            <div className="my-2 flex gap-2">
              {posts.category.split(",").map((v) => {
                return (
                  <div
                    key={v}
                    style={{
                      border: "1px solid #fff",
                    }}
                    onClick={() => {
                      history.push(`/app-def/S-102/blog/?c=${v}`);
                    }}
                    className="bg-black/80 text-white px-2 text-sm flex items-center gap-1 hover:bg-black cursor-pointer"
                  >
                    <FaFolder />
                    <span>{v}</span>
                  </div>
                );
              })}
            </div>
            <div
              className="mb-4 sun-editor-editable !p-0 text-base"
              dangerouslySetInnerHTML={{ __html: posts.contents }}
            ></div>
            <div className="flex flex-wrap items-center gap-2 justify-around my-4">
              <a
                href={`https://twitter.com/intent/tweet?text=${posts.title}&url=https://www.0004s.com/app-def/S-102/blog/${posts._id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-2/5 md:w-48 bg-[#000000] text-white flex justify-center items-center gap-1 text-xl p-2 rounded hover:bg-[#555]"
              >
                <FaXTwitter />X
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://www.0004s.com/app-def/S-102/blog/${posts._id}&t=${posts.title}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-2/5 md:w-48 bg-[#3b5998] text-white flex justify-center items-center gap-1 text-xl p-2 rounded hover:bg-[#5776b8]"
              >
                <FaFacebookF />
                Facebook
              </a>
              <a
                href={`https://timeline.line.me/social-plugin/share?url=https://www.0004s.com/app-def/S-102/blog/${posts._id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-2/5 md:w-48 bg-[#02c301] text-white flex justify-center items-center gap-1 text-xl p-2 rounded hover:bg-[#15e015]"
              >
                <FaLine />
                LINE
              </a>
              <div
                className="w-2/5 md:w-48 bg-[#333333] text-white flex justify-center items-center gap-1 text-xl p-2 rounded cursor-pointer hover:bg-[#777] active:bg-black select-none"
                onClick={() => {
                  navigator.clipboard.writeText(location.href);
                }}
              >
                <FaRegCopy />
                コピー
              </div>
            </div>
          </section>
          <RightPanel />
        </div>
        <div className="container mx-auto flex flex-col gap-4 py-4 bg-white">
          <Comment pId={posts._id} comments={comments} />
          <h3 className="text-xl font-bold">関連記事</h3>
          {relation &&
            relation.map((v) => {
              const num = Math.ceil(window.innerWidth / 16);
              const div = document.createElement("div");
              div.innerHTML = v.contents;
              const text =
                div.innerText.length > num
                  ? div.innerText.slice(0, num) + "..."
                  : div.innerText;
              const cat = v.category.split(",")[0];
              return (
                <div
                  key={v._id}
                  className="flex flex-col md:flex-row gap-1 md:gap-6 items-stretch hover:bg-slate-100/50 rounded cursor-pointer p-2"
                  onClick={() => {
                    history.push(`/app-def/S-102/blog/${v._id}`);
                    document.body.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  <div className="relative rounded overflow-hidden w-full md:w-[30vw] min-w-[200px] aspect-[320/168] max-w-xs">
                    {cat && (
                      <span className="absolute text-white bg-black/60 border-current px-2 py-1 top-1 left-1 border text-sm">
                        {cat}
                      </span>
                    )}
                    <img
                      src="/app-def/S-102/blog/placebo_OGP-2.png"
                      alt=""
                      className="object-cover"
                    />
                  </div>
                  <div className="flex-1 flex flex-col p-1">
                    <h4 className="font-bold text-base md:text-xl">
                      {v.title}
                    </h4>
                    <p className="text-sm flex-1">{text}</p>
                    <div className="flex justify-end items-center gap-1">
                      <IoTimeOutline />
                      <span className="text-sm">
                        {dayjs(v.date).format("YYYY.MM.DD")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>
      <Footer />
    </div>
  );
}
